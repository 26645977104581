<template>
	<el-drawer :wrapperClosable="false" :title="`${dataType == 'Add' ? '新增' : '编辑'}产品信息`" :visible.sync="drawer_"
		destroy-on-close direction="rtl" custom-class="demo-drawer" ref="drawer" size="70%" @open="open" @close="closed">
		<!-- @closed="Object.assign($data, $options.data())" -->

		<div class="demo-drawer__content" style="width: 100%">
			<!-- 抽屉 -->
			<div class="edit" style="padding: 0 30px; width: 100%">
				<p>基本信息</p>

				<el-form :rules="rules" :model="ruleForm" status-icon ref="ruleForm" style="width: 90%" class="demo-ruleForm"
					label-position="rigth" label-width="180px">
					<el-form-item label="产品名称:" :rules="[
		{
			required: true,
			message: '产品名称不能为空',
			trigger: 'change'
		},
	]" prop="financialProductName">
						<el-input placeholder="请输入产品名称" v-model="ruleForm.financialProductName"></el-input>
					</el-form-item>
					<el-form-item label="产品简介:" :rules="[
		{
			required: true,
			message: '产品简介不能为空',
			trigger: 'change'
		},
	]" prop="productIntroduction">
						<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 14 }" placeholder="请输入产品简介"
							v-model="ruleForm.productIntroduction"></el-input>
					</el-form-item>
					<el-form-item label="担保方式:" prop="mortgageOrCredit" :rules="[{ required: true, message: '请选择担保方式' }]">
						<el-radio v-for="item in allDictionaries.MortgageOrCredit" v-model="ruleForm.mortgageOrCredit"
							:label="item.value" :key="item.value">{{ item.label }}</el-radio>
					</el-form-item>
					<el-form-item label="贷款主体:" prop="companyOrPersonal" :rules="[{ required: true, message: '请选择贷款主体' }]">
						<el-radio v-for="item in allDictionaries.CompanyOrPersonal" v-model="ruleForm.companyOrPersonal"
							:label="item.value" :key="item.value">{{ item.label }}</el-radio>
					</el-form-item>
					<el-form-item label="申请方式:" prop="onlineOrOffline" :rules="[{ required: true, message: '请选择申请方式' }]">
						<el-radio v-for="item in allDictionaries.OnlineOrOffline" v-model="ruleForm.onlineOrOffline"
							:label="item.value" :key="item.value">{{ item.label }}</el-radio>
					</el-form-item>

					<el-form-item v-if="ruleForm.mortgageOrCredit == 1" prop="mortgageRate"
						:rules="[{ required: true, message: '请选择抵押成数' }]">
						<div style="font-weight: 400; display: inline-block" slot="label">
							抵押成数
							<el-tooltip effect="dark" placement="right">
								<div v-html="'1、足额是指住宅抵押率7成或以下；<br>2、高额是指住宅抵押率7-10成；<br>3、满额是指住宅抵押率10成；<br>4、超额是指住宅抵押率超过10成；<br>'"
									slot="content"></div>
								<i class="icon iconfont icon-wenhao"></i>
							</el-tooltip>:
						</div>
						<el-radio v-for="item in allDictionaries.MortgageRate" v-model="ruleForm.mortgageRate" :label="item.value"
							:key="item.value">{{ item.label }}</el-radio>
					</el-form-item>

					<el-form-item label="产品标准化:" prop="standard" :rules="[
		{ required: true, message: '请选择产品标准化' },
	]">
						<el-radio v-for="item in filterStandard" v-model="ruleForm.standard" :label="item.value"
							:key="item.value">{{
		item.label }}</el-radio>
					</el-form-item>

					<el-form-item :rules="[{ required: true, message: '请选择用途分类' }]" label="用途分类:" prop="useClassification">
						<el-radio v-for="item in filterUseClassification" v-model="ruleForm.useClassification" :label="item.value"
							:key="item.value">{{ item.label }}</el-radio>
					</el-form-item>
					<el-form-item label="产品类别:" :rules="[
		{
			required: true,
			message: '产品类别不能为空',
		},
	]" prop="productCategory">
						<el-select style="width: 100%" v-model="ruleForm.productCategory" placeholder="请选择">
							<el-option v-for="item in ProductCategory" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="适用区域:">
						<el-cascader :value="ruleForm.applicableAreaId.split(',')" ref="appliCascaderUnit" @change="
		changeNetworkAreaName(
			'applicableAreaId',
			'applicableAreaName',
			'appliCascaderUnit'
		)
		" placeholder="请选择适用区域" :show-all-levels="false" style="width: 100%" filterable :options="mapOptions" :props="{
		multiple: true,
		checkStrictly: true,
		label: 'name',
		emitPath: false,
	}" clearable></el-cascader>
					</el-form-item>



					<p>主要参数</p>

					<el-form-item :rules="[{ required: true, message: '请输入金额上限' }]" prop="amountOnline" class="impose"
						label="金额上限(万元):">
						<el-input-number :min="1" :max="99999" placeholder="请输入金额上限" style="width: 100%; text-align: left"
							controls-position="right" v-model="ruleForm.amountOnline"></el-input-number>
					</el-form-item>
					<el-form-item class="impose" prop="interestRateLower" label="利率区间(%):">
						<div style="display: flex; align-items: center">
							<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
								style="width: 48%; text-align: left" controls-position="right" placeholder="请输入最小年利率"
								v-model="ruleForm.interestRateLower"></el-input>
							<div style="margin: 0 10px">至</div>
							<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
								ref="interestRateUpper_max" style="width: 49%; text-align: left" controls-position="right"
								v-model="ruleForm.interestRateUpper" placeholder="请输入最大年利率"></el-input>
						</div>
					</el-form-item>

					<el-form-item class="impose" prop="termMin" label="最长期限(月):">
						<div style="display: flex; align-items: center">

							<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
								style="width: 49%; text-align: left" controls-position="right" placeholder="请输入最小期限"
								v-model="ruleForm.termMin"></el-input>
							<div style="margin: 0 10px">至</div>
							<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
								ref="term_max" placeholder="请输入最长期限" style="width: 49%; text-align: left" controls-position="right"
								v-model="ruleForm.term"></el-input>
						</div>

					</el-form-item>

					<el-form-item label="还款方式:">
						<el-select style="width: 100%" v-model="ruleForm.productRepaymentMethod" placeholder="请选择">
							<el-option v-for="item in ProductRepaymentMethod" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>


					<el-form-item label="是否每年过桥:" v-if="ruleForm.term > 12">
						<el-radio v-for="item in allDictionaries.CrossBridge" v-model="ruleForm.crossBridge" :label="item.value"
							:key="item.value">{{ item.label }}</el-radio>
					</el-form-item>
					<el-form-item v-if="ruleForm.crossBridge == 4 && ruleForm.term > 12" label="过桥描述:">
						<el-input placeholder="请输入过桥描述" v-model="ruleForm.crossBridgeDesc"></el-input>
					</el-form-item>
					<el-form-item label="额度类型:">
						<el-radio v-for="item in allDictionaries.QuotaType" v-model="ruleForm.quotaType" :label="item.value"
							:key="item.value">{{ item.label }}</el-radio>
					</el-form-item>
					<el-form-item label="提款要求:">
						<el-radio v-for="item in allDictionaries.WithdrawalRequirement" v-model="ruleForm.withdrawalRequirement"
							:label="item.value" :key="item.value">{{ item.label }}</el-radio>
					</el-form-item>
					<el-form-item label="收款账户:">
						<el-checkbox-group v-model="ruleForm.collectionAccount">
							<el-checkbox v-for="(
									item, index
								) in allDictionaries.CollectionAccount" :key="index" :label="item.value">{{ item.label }}</el-checkbox>
						</el-checkbox-group>
					</el-form-item>

					<el-form-item class="impose" label="抵押率(%):" v-if="ruleForm.mortgageOrCredit == 1 &&
		ruleForm.mortgageRate == 1
		">
						<el-input-number placeholder="请输入抵押率" :min="0" :max="100" style="width: 100%; text-align: left"
							controls-position="right" v-model="ruleForm.mortgageRatio"></el-input-number>
					</el-form-item>

					<el-form-item v-if="ruleForm.onlineOrOffline == 1 || ruleForm.onlineOrOffline == 3" label="线上扫码是否查征信:">
						<el-select style="width: 100%" v-model="ruleForm.checkCreditScan" placeholder="请选择">
							<el-option v-for="item in CheckCreditScan" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="备注:">
						<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 14 }" placeholder="请输入内容"
							v-model="ruleForm.otherParameter" style="width: 100%">
						</el-input>
					</el-form-item>
					<p>产品准入</p>
					<template v-if="ruleForm.useClassification == 1">
						<span>企业维度</span>
						<el-form-item class="impose" label="成立年限(月):">
							<el-input-number placeholder="请输入成立年限" style="width: 100%; text-align: left" controls-position="right"
								v-model="ruleForm.incorporationYears"></el-input-number>
						</el-form-item>

						<el-form-item label="所在省市:">
							<el-cascader :value="ruleForm.registeredPlaceId.split(',')" ref="registCascaderUnit" @change="
		changeNetworkAreaName(
			'registeredPlaceId',
			'registeredPlaceName',
			'registCascaderUnit'
		)
		" placeholder="请选择所在省市" :show-all-levels="false" style="width: 100%" filterable :options="mapOptions" :props="{
		multiple: true,
		checkStrictly: true,
		label: 'name',
		emitPath: false,
	}" clearable></el-cascader>
						</el-form-item>
						<el-form-item label="税务等级:"
							v-if="ruleForm.companyOrPersonal == 1 && (ruleForm.onlineOrOffline == 1 || ruleForm.onlineOrOffline == 3)">
							<el-select style="width: 100%" v-model="ruleForm.taxClassification" placeholder="请选择">
								<el-option v-for="item in allDictionaries.TaxClassification" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item class="impose" v-if="ruleForm.companyOrPersonal == 1 && ruleForm.mortgageOrCredit == 2"
							label="上年主营收入(万元):">
							<el-input-number style="width: 100%; text-align: left" controls-position="right" :min="1" :max="99999"
								placeholder="请输入金额" v-model="ruleForm.lastYearInvoiceAmount"></el-input-number>
						</el-form-item>
						<el-form-item label="征信要求:">
							<el-input placeholder="请输入征信要求" v-model="ruleForm.enterpriseCreditRequirements"></el-input>
						</el-form-item>

						<el-form-item label="备注:">
							<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 14 }" placeholder="请输入内容"
								v-model="ruleForm.otherEnterprise" style="width: 100%">
							</el-input>
						</el-form-item>
					</template>
					<template v-if="ruleForm.companyOrPersonal == 1">
						<span>法人维度</span>
						<div class="requirement">
							<el-checkbox :true-label="1" :false-label="''"
								v-model="ruleForm.noWriteActualController">本产品对实控人信息无要求</el-checkbox>
						</div>
						<div v-if="!ruleForm.noWriteActualController">
							<el-form-item class="impose" label="年龄限制(岁):">
								<el-input-number placeholder="请输入年龄限制" style="width: 100%; text-align: left" controls-position="right"
									v-model="ruleForm.ageLimit"></el-input-number>
							</el-form-item>
							<el-form-item label="国籍限制:">
								<el-checkbox-group @change="(val) => groupchange(val, 'internationalRestrictions')"
									v-model="ruleForm.internationalRestrictions">
									<el-checkbox v-for="item in allDictionaries.InternationalRestrictions" :label="item.value"
										:key="item.label">{{
		item.label }}</el-checkbox>
								</el-checkbox-group>
							</el-form-item>
							<el-form-item label="房产要求:">
								<el-radio v-for="item in allDictionaries.PropertyRequirementActualController"
									v-model="ruleForm.propertyRequirementActualController" :label="item.value" :key="item.value">{{
		item.label }}</el-radio>
							</el-form-item>
							<el-form-item label="征信要求:">
								<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 14 }" placeholder="请输入内容"
									v-model="ruleForm.creditRequirements" style="width: 100%">
								</el-input>
							</el-form-item>
							<el-form-item label="配偶征信:">
								<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 14 }" placeholder="请输入内容"
									v-model="ruleForm.spouseCreditRequirements" style="width: 100%">
								</el-input>
							</el-form-item>
							<el-form-item label="备注:">
								<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 14 }" placeholder="请输入内容"
									v-model="ruleForm.otherActualController" style="width: 100%">
								</el-input>
							</el-form-item>
						</div>
					</template>
					<template v-if="ruleForm.companyOrPersonal == 2">
						<span>借款人维度</span>
						<div class="requirement">
							<el-checkbox :true-label="1" :false-label="''"
								v-model="ruleForm.noWriteBorrower">本产品对借款人信息无要求</el-checkbox>
						</div>
						<div v-if="!ruleForm.noWriteBorrower">
							<el-form-item class="impose" label="年龄限制(岁):">
								<el-input-number placeholder="请输入年龄限制" style="width: 100%; text-align: left" controls-position="right"
									v-model="ruleForm.ageLimitBorrower"></el-input-number>
							</el-form-item>
							<el-form-item label="国籍限制:">
								<el-checkbox-group @change="(val) => groupchange(val, 'internationalRestrictionsBorrower')"
									v-model="ruleForm.internationalRestrictionsBorrower">
									<el-checkbox v-for="item in allDictionaries.InternationalRestrictions" :label="item.value"
										:key="item.value">{{
		item.label }}</el-checkbox>
								</el-checkbox-group>
							</el-form-item>
							<el-form-item v-if="ruleForm.mortgageOrCredit == 2" label="房产要求:">
								<el-radio v-for="item in allDictionaries.PropertyRequirements" v-model="ruleForm.propertyRequirements"
									:label="item.value" :key="item.value">{{ item.label }}</el-radio>
							</el-form-item>
							<el-form-item v-if="ruleForm.mortgageOrCredit == 2" label="职业要求:">
								<el-checkbox-group @change="(val) => groupchange(val, 'professionalRequirements')"
									v-model="ruleForm.professionalRequirements">
									<el-checkbox v-for="item in allDictionaries.ProfessionalRequirements" :label="item.value"
										:key="item.value">{{
		item.label }}</el-checkbox>
								</el-checkbox-group>
							</el-form-item>
							<el-form-item v-if="ruleForm.mortgageOrCredit == 1" class="impose" label="持股比例(%):">
								<el-input-number style="width: 100%; text-align: left" controls-position="right"
									v-model="ruleForm.shareholdingRatio" :min="0" :max="100"></el-input-number>
							</el-form-item>
							<el-form-item class="impose" v-if="ruleForm.mortgageOrCredit == 1" label="持股时间(月):">
								<el-input-number style="width: 100%; text-align: left" controls-position="right"
									v-model="ruleForm.holdingPeriod"></el-input-number>
							</el-form-item>
							<el-form-item label="征信要求:">
								<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 14 }" placeholder="请输入内容" v-model="ruleForm.creditRequirementsBorrower
		" style="width: 100%">
								</el-input>
							</el-form-item>
							<el-form-item label="配偶征信:">
								<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 14 }" placeholder="请输入内容" v-model="ruleForm.spouseCreditRequirementsBorrower
		" style="width: 100%">
								</el-input>
							</el-form-item>
							<el-form-item label="流水要求:">
								<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 14 }" placeholder="请输入内容"
									v-model="ruleForm.flowRequirements" style="width: 100%">
								</el-input>
							</el-form-item>
							<el-form-item label="备注:">
								<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 14 }" placeholder="请输入内容"
									v-model="ruleForm.otherBorrower" style="width: 100%">
								</el-input>
							</el-form-item>
						</div>
					</template>
					<template v-if="ruleForm.mortgageOrCredit == 1">
						<span>抵押物要求</span>
						<el-form-item label="抵押物类型:">
							<el-checkbox-group @change="(val) => groupchange(val, 'collateralTypes')"
								v-model="ruleForm.collateralTypes">
								<el-checkbox v-for="item in allDictionaries.CollateralTypes" :label="item.value" :key="item.value">{{
		item.label }}</el-checkbox>
							</el-checkbox-group>
						</el-form-item>
						<el-form-item>
							<span style="
									font-weight: 400;
									position: relative;
									z-index: 1;
								" slot="label">抵押人关系
								<el-tooltip effect="dark" placement="right">
									<span v-html="dyrhtml" slot="content"></span>
									<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
							<el-checkbox-group @change="(val) => groupchange(val, 'mortgagor')" v-model="ruleForm.mortgagor">
								<el-checkbox v-for="item in allDictionaries.Mortgagor" :label="item.value" :key="item.value">{{
		item.label }}</el-checkbox>
							</el-checkbox-group>
						</el-form-item>
						<el-form-item label="抵押物省市:">
							<el-cascader :value="ruleForm.mortgageLocationId.split(',')" ref="registCascaderUnit" @change="
		changeNetworkAreaName(
			'mortgageLocationId',
			'mortgageLocationName',
			'registCascaderUnit'
		)
		" placeholder="请选择抵押物省市" :show-all-levels="false" style="width: 100%" filterable :options="mapOptions" :props="{
		multiple: true,
		checkStrictly: true,
		label: 'name',
		emitPath: false,
	}" clearable></el-cascader>
						</el-form-item>
						<el-form-item label="抵押人年龄（岁）:">
							<el-input v-model="ruleForm.ageOfMortgagor"></el-input>
						</el-form-item>
						<el-form-item label="抵押物楼龄（年）:">
							<el-input v-model="ruleForm.mortgageAge"></el-input>
						</el-form-item>
						<el-form-item class="impose" label="过户时间(月):">
							<el-input-number placeholder="请输入过户时间" style="width: 100%; text-align: left" controls-position="right"
								v-model="ruleForm.transferTime"></el-input-number>
						</el-form-item>

						<el-form-item label="备注:">
							<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 14 }" placeholder="请输入内容"
								v-model="ruleForm.otherMortgage" style="width: 100%">
							</el-input>
						</el-form-item>
					</template>

					<span>其他要求</span>
					<div class="requirement">
						<el-checkbox :true-label="1" :false-label="''"
							v-model="ruleForm.noWriteOtherElements">本产品对其他要素信息无要求</el-checkbox>
					</div>
					<div v-if="!ruleForm.noWriteOtherElements">

						<el-form-item label="其他要求:">
							<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 14 }" placeholder="请输入内容"
								v-model="ruleForm.otherCoreAccess" style="width: 100%">
							</el-input>
						</el-form-item>

					</div>
					<p>禁止准入</p>
					<el-form-item class="impose" label="贷款银行家数限制(家):">
						<el-input-number :min="1" :max="99" placeholder="请输入" style="width: 100%; text-align: left"
							controls-position="right" v-model="ruleForm.loanBankNumberLimit"></el-input-number>
					</el-form-item>
					<el-form-item class="impose" v-if="ruleForm.onlineOrOffline == 1 || ruleForm.onlineOrOffline == 3"
						label="法人变更时间不小于(月) :">
						<el-input-number :min="1" :max="99" placeholder="请输入" style="width: 100%; text-align: left"
							controls-position="right" v-model="ruleForm.corporateChangeTimeNotLessThan"></el-input-number>
					</el-form-item>
					<el-form-item label="征信白户可否准入:"
						v-if="(ruleForm.onlineOrOffline == 1 || ruleForm.onlineOrOffline == 3) && ruleForm.mortgageOrCredit == 2">
						<el-radio v-for="item in allDictionaries.WhetherWhiteHouseholdsAdmitted"
							v-model="ruleForm.whetherWhiteHouseholdsAdmitted" :label="item.value" :key="item.value">{{ item.label
							}}</el-radio>
					</el-form-item>
					<el-form-item label="上年度亏损可否准入:"
						v-if="(ruleForm.onlineOrOffline == 1 || ruleForm.onlineOrOffline == 3) && ruleForm.mortgageOrCredit == 2">
						<el-radio v-for="item in allDictionaries.PreviousYearsLossesAllowedForAdmission"
							v-model="ruleForm.previousYearsLossesAllowedForAdmission" :label="item.value" :key="item.value">{{
		item.label
	}}</el-radio>
					</el-form-item>
					<el-form-item label="有小贷余额可否准入:"
						v-if="(ruleForm.onlineOrOffline == 1 || ruleForm.onlineOrOffline == 3) && ruleForm.mortgageOrCredit == 2">
						<el-radio v-for="item in allDictionaries.MicroloanBalanceAllowedForAdmission"
							v-model="ruleForm.microloanBalanceAllowedForAdmission" :label="item.value" :key="item.value">{{ item.label
							}}</el-radio>
					</el-form-item>
					<el-form-item label="补充说明:">
						<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 14 }" placeholder="请输入内容"
							v-model="ruleForm.prohibitionAccess" style="width: 100%">
						</el-input>
					</el-form-item>
					<p>额度计算</p>
					<el-form-item class="mb" :label="index == 0 ? '额度计算:' : ''" v-for="(
							item, index
						) in ruleForm.quotaAccountingCoreLogic" :key="'C' + index">
						<div style="display: flex; align-items: center">
							<el-input placeholder="请输入额度计算" v-model="item.value"></el-input>
							<i style="font-size: 30px; margin-left: 10px" :class="[
		index == 0
			? 'el-icon-circle-plus-outline'
			: 'el-icon-remove-outline',
	]" @click="
		addFactor(index, 'quotaAccountingCoreLogic')
		"></i>
						</div>
					</el-form-item>
					<el-form-item class="mb" :label="index == 0 ? '风控要点:' : ''" v-for="(
							item, index
						) in ruleForm.basicRiskControlMeasures" :key="'G' + index">
						<div style="display: flex; align-items: center">
							<el-input placeholder="请输入风控要点" v-model="item.value"></el-input>
							<i style="font-size: 30px; margin-left: 10px" :class="[
		index == 0
			? 'el-icon-circle-plus-outline'
			: 'el-icon-remove-outline',
	]" @click="
		addFactor(index, 'basicRiskControlMeasures')
		"></i>
						</div>
					</el-form-item>
					<el-form-item label="补充说明:">
						<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 14 }" placeholder="请输入内容"
							v-model="ruleForm.supplementaryStatement" style="width: 100%">
						</el-input>
					</el-form-item>
					<p>专家解读</p>


					<el-form-item class="mb" :label="index == 0 ? '核心优势:' : ''" v-for="(item, index) in ruleForm.maiAdvantages"
						:key="'K' + index">
						<div style="display: flex; align-items: center">
							<el-input placeholder="请输入核心优势" v-model="item.value"></el-input>
							<i style="font-size: 30px; margin-left: 10px" :class="[
		index == 0
			? 'el-icon-circle-plus-outline'
			: 'el-icon-remove-outline',
	]" @click="addFactor(index, 'maiAdvantages')"></i>
						</div>
					</el-form-item>
					<el-form-item class="mb" :label="index == 0 ? '产品劣势:' : ''"
						v-for="(item, index) in ruleForm.mainDisadvantages" :key="'L' + index">
						<div style="display: flex; align-items: center">
							<el-input placeholder="请输入产品劣势" v-model="item.value"></el-input>
							<i style="font-size: 30px; margin-left: 10px" :class="[
		index == 0
			? 'el-icon-circle-plus-outline'
			: 'el-icon-remove-outline',
	]" @click="addFactor(index, 'mainDisadvantages')"></i>
						</div>
					</el-form-item>
					<el-form-item class="mb" :label="index == 0 ? '适客人群:' : ''"
						v-for="(item, index) in ruleForm.suitableCustomers" :key="'Z' + index">
						<div style="display: flex; align-items: center">
							<el-input placeholder="请输入适客人群" v-model="item.value"></el-input>
							<i style="font-size: 30px; margin-left: 10px" :class="[
		index == 0
			? 'el-icon-circle-plus-outline'
			: 'el-icon-remove-outline',
	]" @click="addFactor(index, 'suitableCustomers')"></i>
						</div>
					</el-form-item>
					<p>产品附件</p>
					<el-form-item label="资料清单:">
						<el-upload :headers="{
		token: $store.state.userInfo.token,
	}" class="upload-demo" name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`" :on-success="(file, fileList) =>
		handleChange(
			file,
			fileList,
			'informationListAttachment'
		)
		" :on-remove="(file, fileList, index) =>
		handleRemove(
			file,
			fileList,
			index,
			'informationListAttachment'
		)
		" multiple :limit="3" :file-list="ruleForm.informationListAttachment">
							<el-button size="small" type="primary">点击上传</el-button>
						</el-upload>
					</el-form-item>
					<el-form-item label="产品说明书:">
						<el-upload :headers="{
		token: $store.state.userInfo.token,
	}" class="upload-demo" name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`" :on-success="(file, fileList) =>
		handleChange(
			file,
			fileList,
			'productInstructionAttachment'
		)
		" :on-remove="(file, fileList, index) =>
		handleRemove(
			file,
			fileList,
			index,
			'productInstructionAttachment'
		)
		" multiple :limit="3" :file-list="ruleForm.productInstructionAttachment">
							<el-button size="small" type="primary">点击上传</el-button>
						</el-upload>
					</el-form-item>
					<el-form-item label="其它附件:">
						<el-upload :headers="{
		token: $store.state.userInfo.token,
	}" class="upload-demo" name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`" :on-success="(file, fileList) =>
		handleChange(
			file,
			fileList,
			'otherAttachment'
		)
		" :on-remove="(file, fileList, index) =>
		handleRemove(
			file,
			fileList,
			index,
			'otherAttachment'
		)
		" multiple :limit="3" :file-list="ruleForm.otherAttachment">
							<el-button size="small" type="primary">点击上传</el-button>
						</el-upload>
					</el-form-item>

				</el-form>

				<div class="demo-drawer__footer">
					<el-button type="primary" @click="postAddFinancialProduct">保 存</el-button>
					<el-button @click="$emit('close')">关 闭</el-button>
				</div>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import {
	addFinancialProduct,
	readFinancialProduct,
	updateFinancialProduct,
} from '@/api/index.js';
export default {
	name: 'infoDrawer',
	props: {
		dialogVisible: {
			type: Boolean,
			required: true,
		},
		allDictionaries: {
			type: Object,
			required: true,
		},
		mapOptions: {
			type: Array,
			required: true,
		},
		ProductCategory: {
			type: Array,
			required: true,
		},
		ProductRepaymentMethod: {
			type: Array,
			required: true,
		},
		CheckCreditScan: {
			type: Array,
			required: true,
		},
		dataType: {
			type: String,
			required: true,
		},
		dataObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		const InterestRate = (rule, value, callback) => {

			const interestRate_max = this.$refs.interestRateUpper_max && this.$refs.interestRateUpper_max.value;
			if (!interestRate_max) {
				callback(new Error('请输入数据'));
			}
			if (value && interestRate_max && value <= interestRate_max) {
				callback();
			} else {
				if (!value) {
					callback(new Error('请输入最小值'));
				}
				if (!interestRate_max) {
					callback(new Error('请输入最大值'));
				}
				if (value > interestRate_max) {
					callback(new Error('最小值不能大于最大值'));
				}
			}
		};
		const InterestRate1 = (rule, value, callback) => {


			const interestRate_max = this.$refs.term_max && this.$refs.term_max.value;
			if (!interestRate_max) {
				callback(new Error('请输入数据'));
			}
			if (value && interestRate_max && value <= interestRate_max) {
				callback();
			} else {
				if (!value) {
					callback(new Error('请输入最小值'));
				}
				if (!interestRate_max) {
					callback(new Error('请输入最大值'));
				}
				if (value > interestRate_max) {
					callback(new Error('最小值不能大于最大值'));
				}
			}
		};
		return {
			rules: {
				interestRateLower: [{ validator: InterestRate, trigger: "blur", required: true, }],
				termMin: [{ validator: InterestRate1, trigger: "blur", required: true, }],
			},
			dyrhtml: `<b>1类关系人</b>
<p style='padding-left:10px'>1、法定代表人、2、公司股东、3、法人及股东直系亲属、6、本公司或管理公司名下资</p>
<b>2类关系人</b>
<p style='padding-left:10px'>4、实际控制人、5、实际控制人直系亲属</p>
<b>3类关系人</b>
<p style='padding-left:10px'>7、法人/股东/实控人非直系亲属</p>
<b>4类关系人</b>
<p style='padding-left:10px'>8、本公司员工及其直系亲属名下资产</p>
<b>5类关系人</b>
<p style='padding-left:10px'>9、其他第三方</p>`,
			ruleForm: {
				financialOrganizationsId: '',
				productCategory: '',
				financialOrganizationsName: '',
				cooperationPresidentId: '',
				financialProductName: '',
				productIntroduction: '',
				applicableAreaId: '1964',
				applicableAreaName: '深圳市',
				companyOrPersonal: '',
				onlineOrOffline: '',
				mortgageOrCredit: '',
				mortgageRate: '',
				standard: '',
				useClassification: '',
				incorporationYears: undefined,
				registeredPlaceId: '1964',
				registeredPlaceName: '深圳市',
				enterpriseOperation: '',
				enterpriseCreditRequirements: '',
				lastYearInvoiceAmount: undefined,
				taxClassification: '',
				otherEnterprise: '',
				internationalRestrictions: [],
				ageLimit: undefined,
				creditRequirements: '',
				spouseCreditRequirements: '',
				otherActualController: '',
				internationalRestrictionsBorrower: [],
				propertyRequirementActualController: '',
				ageLimitBorrower: undefined,
				creditRequirementsBorrower: '',
				spouseCreditRequirementsBorrower: '',
				shareholdingRatio: '',
				holdingPeriod: '',
				flowRequirements: '',
				propertyRequirements: '',
				professionalRequirements: [],
				otherBorrower: '',
				collateralTypes: [1],
				mortgagor: [],
				mortgageLocationId: '1964',
				mortgageLocationName: '深圳市',
				transferTime: undefined,
				mortgageAge: '',
				ageOfMortgagor: '',
				otherMortgage: '',
				loanBankNumberLimit: '',
				corporateChangeTimeNotLessThan: '',
				whetherWhiteHouseholdsAdmitted: '',
				previousYearsLossesAllowedForAdmission: '',
				microloanBalanceAllowedForAdmission: '',
				supplementaryStatement: '',
				otherCoreAccess: '',
				prohibitionAccess: '',
				quotaAccountingCoreLogic: [{ value: '' }],
				quotaAccountingSupplementaryLogic: [{ value: '' }],
				mortgageFocusIndicators: [{ value: '' }],
				creditFocusIndicators: [{ value: '' }],
				otherFocusIndicators: [{ value: '' }],
				amountOnline: undefined,
				interestRateLower: '',
				interestRateUpper: '',
				term: '',
				termMin: '',
				productRepaymentMethod: '',
				checkCreditScan: '',
				repaymentMethodDesc: '',
				productRepaymentMethodName: '',
				quotaType: '',
				mortgageRatio: undefined,
				collectionAccount: [],
				crossBridge: '',
				crossBridgeDesc: '',
				withdrawalRequirement: '',
				otherParameter: '',
				basicRiskControlMeasures: [{ value: '' }],
				commonRiskControlMeasures: [{ value: '' }],
				focusRiskPoints: [{ value: '' }],
				maiAdvantages: [{ value: '' }],
				mainDisadvantages: [{ value: '' }],
				suitableCustomers: [{ value: '' }],
				informationListAttachment: [],
				productInstructionAttachment: [],
				otherAttachment: [],
				remark: '',
				financialProductId: '',
				noWriteActualController: '',
				noWriteBorrower: '',
				noWriteOtherElements: '',
			},
			unCompanyOrPersonal: null,
			unMortgageOrCredit: null,
			unMortgageRate: null,
			unOnlineOrOffline: null,
			unUseClassification: null,
		};
	},
	methods: {
		groupchange(value, name) {
			console.log(value[value.length - 1]);
			if (value[value.length - 1] == 100) {
				this.ruleForm[name] = [100]
			} else {
				this.ruleForm[name] = value.filter(item => item !== 100);
			}

		},
		postAddFinancialProduct() {
			this.$refs['ruleForm'].validate(async (valid) => {
				if (valid) {
					const ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
					if (ruleForm.useClassification == 2) {
						ruleForm.shareholdingRatio = '';
						ruleForm.holdingPeriod = '';
					}


					ruleForm.collectionAccount =
						ruleForm.collectionAccount.join();

					ruleForm.productCategory =
						this.ruleForm.productCategory || '';

					ruleForm.internationalRestrictions =
						this.ruleForm.internationalRestrictions.join();
					ruleForm.internationalRestrictionsBorrower =
						this.ruleForm.internationalRestrictionsBorrower.join();
					ruleForm.professionalRequirements =
						this.ruleForm.professionalRequirements.join();
					ruleForm.mortgagor = this.ruleForm.mortgagor.join();
					ruleForm.collateralTypes =
						this.ruleForm.collateralTypes.join();
					ruleForm.informationListAttachment = JSON.stringify(
						this.ruleForm.informationListAttachment.map((item) => ({
							fileName: item.name,
							filePath: item.url,
						}))
					);
					ruleForm.productInstructionAttachment = JSON.stringify(
						this.ruleForm.productInstructionAttachment.map(
							(item) => ({
								fileName: item.name,
								filePath: item.url,
							})
						)
					);
					ruleForm.otherAttachment = JSON.stringify(
						this.ruleForm.otherAttachment.map((item) => ({
							fileName: item.name,
							filePath: item.url,
						}))
					);

					ruleForm.quotaAccountingCoreLogic =
						this.ruleForm.quotaAccountingCoreLogic.map(
							(item) => item.value || ''
						);
					ruleForm.quotaAccountingSupplementaryLogic =
						this.ruleForm.quotaAccountingSupplementaryLogic.map(
							(item) => item.value || ''
						);
					ruleForm.mortgageFocusIndicators =
						this.ruleForm.mortgageFocusIndicators.map(
							(item) => item.value || ''
						);
					ruleForm.creditFocusIndicators =
						this.ruleForm.creditFocusIndicators.map(
							(item) => item.value || ''
						);
					ruleForm.otherFocusIndicators =
						this.ruleForm.otherFocusIndicators.map(
							(item) => item.value || ''
						);

					ruleForm.basicRiskControlMeasures =
						this.ruleForm.basicRiskControlMeasures.map(
							(item) => item.value || ''
						);

					ruleForm.commonRiskControlMeasures =
						this.ruleForm.commonRiskControlMeasures.map(
							(item) => item.value || ''
						);
					ruleForm.focusRiskPoints =
						this.ruleForm.focusRiskPoints.map(
							(item) => item.value || ''
						);
					ruleForm.maiAdvantages = this.ruleForm.maiAdvantages.map(
						(item) => item.value || ''
					);
					ruleForm.mainDisadvantages =
						this.ruleForm.mainDisadvantages.map(
							(item) => item.value || ''
						);
					ruleForm.suitableCustomers =
						this.ruleForm.suitableCustomers.map(
							(item) => item.value || ''
						);
					if (ruleForm.productRepaymentMethod != 5) {
						ruleForm.repaymentMethodDesc = '';
					}
					if (ruleForm.term < 12) {
						ruleForm.crossBridge = '';
					}
					if (ruleForm.crossBridge != 4) {
						ruleForm.crossBridgeDesc = '';
					}
					let res;
					if (this.dataType == 'Edit') {
						res = await updateFinancialProduct(ruleForm);
					} else {
						res = await addFinancialProduct(ruleForm);
					}
					if (res.returncode == 0) {
						this.$message({
							message: '保存成功',
							type: 'success',
						});
						this.$emit('saveData');
					}
				} else {
					this.$message({
						message: '必填项不能为空',
						type: 'warning',
					});
					return false;
				}
			});
		},
		handleChange(file, fileList, name) {
			this.ruleForm[name].push({
				name: fileList.response.list[0].fileName,
				url: fileList.response.list[0].filePath,
			});
		},
		async open() {
			this.ruleForm.financialOrganizationsId =
				(this.$store.state.userInfo &&
					this.$store.state.userInfo.financialOrganizationsId) ||
				0;
			this.ruleForm.financialOrganizationsName =
				(this.$store.state.userInfo &&
					this.$store.state.userInfo.financialOrganizationsName) ||
				'';
			this.ruleForm.cooperationPresidentId =
				(this.$store.state.userInfo &&
					this.$store.state.userInfo.userVo.cooperationPresidentId) ||
				'';
			if (this.dataType == 'Edit') {
				const res = await readFinancialProduct({
					financialProductId: this.dataObj.financialProductId,
				});
				this.ruleForm.financialProductId =
					this.dataObj.financialProductId;
				this.ruleForm.financialProductName =
					res.data.financialProductName || '';
				this.ruleForm.productIntroduction =
					res.data.productIntroduction || '';

				this.ruleForm.applicableAreaId =
					res.data.applicableAreaId || '1964';
				this.ruleForm.applicableAreaName =
					res.data.applicableAreaName || '深圳市';
				this.ruleForm.companyOrPersonal =
					res.data.companyOrPersonal || '';

				this.ruleForm.noWriteActualController =
					res.data.noWriteActualController || '';
				this.ruleForm.noWriteBorrower = res.data.noWriteBorrower || '';
				this.ruleForm.noWriteOtherElements =
					res.data.noWriteOtherElements || '';

				this.ruleForm.onlineOrOffline = res.data.onlineOrOffline || '';
				this.ruleForm.mortgageOrCredit =
					res.data.mortgageOrCredit || '';
				this.ruleForm.mortgageRate = res.data.mortgageRate || '';
				this.ruleForm.standard = res.data.standard || '';
				this.ruleForm.useClassification =
					res.data.useClassification || '';
				this.ruleForm.incorporationYears =
					res.data.incorporationYears === '' ? undefined : res.data.incorporationYears;
				this.ruleForm.registeredPlaceId =
					res.data.registeredPlaceId || '1964';
				this.ruleForm.registeredPlaceName =
					res.data.registeredPlaceName || '深圳市';
				this.ruleForm.enterpriseOperation =
					res.data.enterpriseOperation || '';
				this.ruleForm.enterpriseCreditRequirements =
					res.data.enterpriseCreditRequirements || '';
				this.ruleForm.lastYearInvoiceAmount = res.data.lastYearInvoiceAmount === '' ? undefined : res.data.lastYearInvoiceAmount;
				this.ruleForm.propertyRequirementActualController =
					res.data.propertyRequirementActualController || '';

				this.ruleForm.taxClassification =
					res.data.taxClassification || '';
				this.ruleForm.otherEnterprise = res.data.otherEnterprise || '';
				this.ruleForm.ageLimit = res.data.ageLimit === '' ? undefined : res.data.ageLimit;
				this.ruleForm.creditRequirements =
					res.data.creditRequirements || '';
				this.ruleForm.spouseCreditRequirements =
					res.data.spouseCreditRequirements || '';
				this.ruleForm.otherActualController =
					res.data.otherActualController || '';
				this.ruleForm.ageLimitBorrower = res.data.ageLimitBorrower === '' ? undefined : res.data.ageLimitBorrower;
				this.ruleForm.creditRequirementsBorrower =
					res.data.creditRequirementsBorrower || '';
				this.ruleForm.spouseCreditRequirementsBorrower =
					res.data.spouseCreditRequirementsBorrower || '';
				this.ruleForm.shareholdingRatio =
					res.data.shareholdingRatio || '';
				this.ruleForm.holdingPeriod = res.data.holdingPeriod || '';
				this.ruleForm.flowRequirements =
					res.data.flowRequirements || '';
				this.ruleForm.propertyRequirements =
					res.data.propertyRequirements || '';
				this.ruleForm.otherBorrower = res.data.otherBorrower || '';
				this.ruleForm.mortgageLocationId =
					res.data.mortgageLocationId || '1964';
				this.ruleForm.mortgageLocationName =
					res.data.mortgageLocationName || '深圳市';
				this.ruleForm.transferTime = res.data.transferTime === '' ? undefined : res.data.transferTime;
				this.ruleForm.mortgageAge = res.data.mortgageAge || '';
				this.ruleForm.ageOfMortgagor = res.data.ageOfMortgagor || '';

				this.ruleForm.crossBridgeDesc = res.data.crossBridgeDesc || '';
				this.ruleForm.withdrawalRequirement = res.data.withdrawalRequirement || '';
				this.ruleForm.otherMortgage = res.data.otherMortgage || '';
				this.ruleForm.supplementaryStatement = res.data.supplementaryStatement || '';

				this.ruleForm.loanBankNumberLimit = res.data.loanBankNumberLimit || '';
				this.ruleForm.corporateChangeTimeNotLessThan = res.data.corporateChangeTimeNotLessThan || '';
				this.ruleForm.whetherWhiteHouseholdsAdmitted = res.data.whetherWhiteHouseholdsAdmitted || '';
				this.ruleForm.previousYearsLossesAllowedForAdmission = res.data.previousYearsLossesAllowedForAdmission || '';
				this.ruleForm.microloanBalanceAllowedForAdmission = res.data.microloanBalanceAllowedForAdmission || '';

				this.ruleForm.amountOnline = res.data.amountOnline === '' ? undefined : res.data.amountOnline;
				this.ruleForm.interestRateLower = res.data.interestRateLower || 1;
				this.ruleForm.interestRateUpper = res.data.interestRateUpper || 1;
				this.ruleForm.term = res.data.term || 1;
				this.ruleForm.termMin = res.data.termMin || 1;

				this.ruleForm.productRepaymentMethodName =
					res.data.productRepaymentMethodName || '';
				this.ruleForm.productRepaymentMethod =
					res.data.productRepaymentMethod || '';
				this.ruleForm.checkCreditScan = res.data.checkCreditScan || '';
				this.ruleForm.repaymentMethodDesc =
					res.data.repaymentMethodDesc || '';
				this.ruleForm.quotaType = res.data.quotaType || '';
				this.ruleForm.mortgageRatio = res.data.mortgageRatio === '' ? undefined : res.data.mortgageRatio;
				this.ruleForm.prohibitionAccess = res.data.prohibitionAccess || '';

				this.ruleForm.crossBridge = res.data.crossBridge || '';
				this.ruleForm.otherParameter = res.data.otherParameter || '';
				this.ruleForm.remark = res.data.remark || '';
				this.ruleForm.productType = res.data.productType || '';
				this.ruleForm.specialty = res.data.specialty || '';
				this.ruleForm.productCategory =
					res.data.productCategory || '';
				this.ruleForm.productManager = res.data.productManager || '';
				this.ruleForm.productManagerB = res.data.productManagerB || '';
				this.ruleForm.currentSalesVolume =
					res.data.currentSalesVolume || '';
				this.ruleForm.otherCoreAccess = res.data.otherCoreAccess || ''

				if (res.data.collateralTypes) {
					this.ruleForm.collateralTypes = res.data.collateralTypes
						.split(',')
						.map((item) => Number(item));
				} else {
					this.ruleForm.collateralTypes = [1];
				}

				if (res.data.internationalRestrictionsBorrower) {
					this.ruleForm.internationalRestrictionsBorrower =
						res.data.internationalRestrictionsBorrower
							.split(',')
							.map((item) => Number(item));
				} else {
					this.ruleForm.internationalRestrictionsBorrower = [];
				}
				if (res.data.professionalRequirements) {
					this.ruleForm.professionalRequirements =
						res.data.professionalRequirements
							.split(',')
							.map((item) => Number(item));
				} else {
					this.ruleForm.professionalRequirements = [];
				}
				if (res.data.collectionAccount) {
					this.ruleForm.collectionAccount = res.data.collectionAccount
						.split(',')
						.map((item) => Number(item));
				} else {
					this.ruleForm.collectionAccount = [];
				}

				if (res.data.mortgagor) {
					this.ruleForm.mortgagor = res.data.mortgagor
						.split(',')
						.map((item) => Number(item));
				} else {
					this.ruleForm.mortgagor = [];
				}

				if (res.data.internationalRestrictions) {
					this.ruleForm.internationalRestrictions =
						res.data.internationalRestrictions
							.split(',')
							.map((item) => Number(item));
				} else {
					this.ruleForm.internationalRestrictions = [];
				}

				if (res.data.informationListAttachment) {
					this.ruleForm.informationListAttachment = JSON.parse(
						res.data.informationListAttachment
					).map((item) => ({
						name: item.fileName,
						url: item.filePath,
					}));
				} else {
					this.ruleForm.informationListAttachment = [];
				}

				if (res.data.productInstructionAttachment) {
					this.ruleForm.productInstructionAttachment = JSON.parse(
						res.data.productInstructionAttachment
					).map((item) => ({
						name: item.fileName,
						url: item.filePath,
					}));
				} else {
					this.ruleForm.productInstructionAttachment = [];
				}

				if (res.data.otherAttachment) {
					this.ruleForm.otherAttachment = JSON.parse(
						res.data.otherAttachment
					).map((item) => ({
						name: item.fileName,
						url: item.filePath,
					}));
				} else {
					this.ruleForm.otherAttachment = [];
				}


				this.ruleForm.quotaAccountingCoreLogic = res.list.reduce(
					(prev, cur) => {
						if (
							cur.informationProlect == 3 &&
							cur.informationCategory == 1
						) {
							prev.push({
								value: cur.informationContent,
							});
						}
						return prev;
					},
					[]
				);
				if (!this.ruleForm.quotaAccountingCoreLogic.length) {
					this.ruleForm.quotaAccountingCoreLogic.push({});
				}
				this.ruleForm.quotaAccountingSupplementaryLogic =
					res.list.reduce((prev, cur) => {
						if (
							cur.informationProlect == 3 &&
							cur.informationCategory == 2
						) {
							prev.push({
								value: cur.informationContent,
							});
						}
						return prev;
					}, []);
				if (!this.ruleForm.quotaAccountingSupplementaryLogic.length) {
					this.ruleForm.quotaAccountingSupplementaryLogic.push({});
				}
				this.ruleForm.mortgageFocusIndicators = res.list.reduce(
					(prev, cur) => {
						if (
							cur.informationProlect == 4 &&
							cur.informationCategory == 1
						) {
							prev.push({
								value: cur.informationContent,
							});
						}
						return prev;
					},
					[]
				);
				if (!this.ruleForm.mortgageFocusIndicators.length) {
					this.ruleForm.mortgageFocusIndicators.push({});
				}
				this.ruleForm.creditFocusIndicators = res.list.reduce(
					(prev, cur) => {
						if (
							cur.informationProlect == 4 &&
							cur.informationCategory == 2
						) {
							prev.push({
								value: cur.informationContent,
							});
						}
						return prev;
					},
					[]
				);
				if (!this.ruleForm.creditFocusIndicators.length) {
					this.ruleForm.creditFocusIndicators.push({});
				}
				this.ruleForm.otherFocusIndicators = res.list.reduce(
					(prev, cur) => {
						if (
							cur.informationProlect == 4 &&
							cur.informationCategory == 3
						) {
							prev.push({
								value: cur.informationContent,
							});
						}
						return prev;
					},
					[]
				);
				if (!this.ruleForm.otherFocusIndicators.length) {
					this.ruleForm.otherFocusIndicators.push({});
				}
				this.ruleForm.basicRiskControlMeasures = res.list.reduce(
					(prev, cur) => {
						if (
							cur.informationProlect == 5 &&
							cur.informationCategory == 1
						) {
							prev.push({
								value: cur.informationContent,
							});
						}
						return prev;
					},
					[]
				);
				if (!this.ruleForm.basicRiskControlMeasures.length) {
					this.ruleForm.basicRiskControlMeasures.push({});
				}
				this.ruleForm.commonRiskControlMeasures = res.list.reduce(
					(prev, cur) => {
						if (
							cur.informationProlect == 5 &&
							cur.informationCategory == 2
						) {
							prev.push({
								value: cur.informationContent,
							});
						}
						return prev;
					},
					[]
				);
				if (!this.ruleForm.commonRiskControlMeasures.length) {
					this.ruleForm.commonRiskControlMeasures.push({});
				}
				this.ruleForm.focusRiskPoints = res.list.reduce((prev, cur) => {
					if (
						cur.informationProlect == 5 &&
						cur.informationCategory == 3
					) {
						prev.push({
							value: cur.informationContent,
						});
					}
					return prev;
				}, []);
				if (!this.ruleForm.focusRiskPoints.length) {
					this.ruleForm.focusRiskPoints.push({});
				}
				this.ruleForm.maiAdvantages = res.list.reduce((prev, cur) => {
					if (
						cur.informationProlect == 6 &&
						cur.informationCategory == 1
					) {
						prev.push({
							value: cur.informationContent,
						});
					}
					return prev;
				}, []);
				if (!this.ruleForm.maiAdvantages.length) {
					this.ruleForm.maiAdvantages.push({});
				}
				this.ruleForm.mainDisadvantages = res.list.reduce(
					(prev, cur) => {
						if (
							cur.informationProlect == 6 &&
							cur.informationCategory == 2
						) {
							prev.push({
								value: cur.informationContent,
							});
						}
						return prev;
					},
					[]
				);
				if (!this.ruleForm.mainDisadvantages.length) {
					this.ruleForm.mainDisadvantages.push({});
				}
				this.ruleForm.suitableCustomers = res.list.reduce(
					(prev, cur) => {
						if (
							cur.informationProlect == 7 &&
							cur.informationCategory == 1
						) {
							prev.push({
								value: cur.informationContent,
							});
						}
						return prev;
					},
					[]
				);
				if (!this.ruleForm.suitableCustomers.length) {
					this.ruleForm.suitableCustomers.push({});
				}
			}
			// 贷款主体 公司 个人
			this.unCompanyOrPersonal = this.$watch(
				'ruleForm.companyOrPersonal',
				() => {

					this.ruleForm.useClassification = '';
					this.ruleForm.lastYearInvoiceAmount = undefined;
					this.ruleForm.internationalRestrictions = [];
					this.ruleForm.ageLimit = undefined;
					this.ruleForm.creditRequirements = '';
					this.ruleForm.spouseCreditRequirements = '';
					this.ruleForm.otherActualController = '';
					this.ruleForm.internationalRestrictionsBorrower = [];
					this.ruleForm.ageLimitBorrower = undefined;
					this.ruleForm.creditRequirementsBorrower = '';
					this.ruleForm.creditRequirementsBorrower = '';
					this.ruleForm.shareholdingRatio = '';
					this.ruleForm.holdingPeriod = '';
					this.ruleForm.flowRequirements = '';
					this.ruleForm.propertyRequirements = '';
					this.ruleForm.professionalRequirements = [];
					this.ruleForm.otherBorrower = '';
					this.ruleForm.taxClassification = '';
					this.ruleForm.mortgageAge = '';
					this.ruleForm.propertyRequirementActualController = '';
					this.ruleForm.ageOfMortgagor = '';

					this.ruleForm.collectionAccount = [];
					this.ruleForm.crossBridge = '';
					this.ruleForm.withdrawalRequirement = '';
				}
			);
			// 担保方式 信用  抵押 
			this.unMortgageOrCredit = this.$watch(
				'ruleForm.mortgageOrCredit',
				() => {
					this.ruleForm.professionalRequirements = [];
					this.ruleForm.propertyRequirements = '';
					this.ruleForm.ageOfMortgagor = '';
					this.ruleForm.whetherWhiteHouseholdsAdmitted = '';
					this.ruleForm.previousYearsLossesAllowedForAdmission = '';
					this.ruleForm.microloanBalanceAllowedForAdmission = '';

					this.ruleForm.lastYearInvoiceAmount = undefined;
					this.ruleForm.mortgageRate = '';
					this.ruleForm.collateralTypes = [1];
					this.ruleForm.mortgagor = [];
					this.ruleForm.mortgageLocationId = '1964';
					this.ruleForm.transferTime = undefined;
					this.ruleForm.otherMortgage = '';
					this.ruleForm.mortgageAge = '';
					this.ruleForm.mortgageFocusIndicators = [{ value: '' }];
					this.ruleForm.mortgageRatio = undefined;
				}
			);
			// 抵押成数 足额  高额  超额
			this.unMortgageRate = this.$watch('ruleForm.mortgageRate', () => {
				this.ruleForm.standard = '';
				this.ruleForm.mortgageRatio = undefined;
			});
			// 申请方式  纯线上  半线上  线下
			this.unOnlineOrOffline = this.$watch(
				'ruleForm.onlineOrOffline',
				() => {
					this.ruleForm.standard = '';
					this.ruleForm.taxClassification = '';
					this.ruleForm.corporateChangeTimeNotLessThan = '';
					this.ruleForm.whetherWhiteHouseholdsAdmitted = '';
					this.ruleForm.previousYearsLossesAllowedForAdmission = '';
					this.ruleForm.microloanBalanceAllowedForAdmission = '';

				}
			);
			// 用途分类  经营  消费 
			this.unUseClassification = this.$watch(
				'ruleForm.useClassification',
				() => {
					this.ruleForm.incorporationYears = undefined;
					this.ruleForm.registeredPlaceId = '1964';
					this.ruleForm.enterpriseOperation = '';
					this.ruleForm.enterpriseCreditRequirements = '';
					this.ruleForm.lastYearInvoiceAmount = undefined;
					this.ruleForm.taxClassification = '';
					this.ruleForm.otherEnterprise = '';
				}
			);
		},
		closed() {
			// this.$refs['ruleForm'].resetFields();
			this.unCompanyOrPersonal();
			this.unMortgageOrCredit();
			this.unMortgageRate();
			this.unOnlineOrOffline();
			this.unUseClassification();
			Object.assign(this.$data, this.$options.data());
		},
		changeNetworkAreaName(id, name, myRef) {
			this.$nextTick(() => {
				const checkedNodes = this.$refs[myRef].getCheckedNodes(); //在data里面找，如下图
				const networkAreaIdLIst = [];
				const networkAreaNameList = [];
				checkedNodes.forEach((item) => {
					networkAreaIdLIst.push(item.value);
					networkAreaNameList.push(item.label);
				});
				this.ruleForm[id] = networkAreaIdLIst.join();
				this.ruleForm[name] = networkAreaNameList.join();
			});
		},
		addFactor(index, name) {
			if (!index) {
				this.ruleForm[name].push({
					value: '',
				});
			} else {
				this.ruleForm[name].splice(index, 1);
			}
		},
		handleRemove(file, fileList, index, name) {
			this.ruleForm[name].splice(index, 1);
		},
	},
	computed: {
		drawer_: {
			get() {
				return this.dialogVisible;
			},
			set(v) {
				this.$emit('update:dialogVisible', v);
			},
		},
		filterUseClassification() {
			if (this.ruleForm.companyOrPersonal == 1) {
				return this.allDictionaries.UseClassification.filter(
					(item) => item.label != '消费'
				);
			} else {
				return this.allDictionaries.UseClassification;
			}
		},
		filterMortgageFocusIndicators() {
			if (this.ruleForm.mortgageOrCredit == 1) {
				return this.ruleForm.mortgageFocusIndicators;
			} else {
				return [];
			}
		},
		filterStandard() {
			if (
				(this.ruleForm.onlineOrOffline == 1 || this.ruleForm.onlineOrOffline == 3) &&
				this.ruleForm.mortgageRate == 1
			) {
				return this.allDictionaries.Standard.filter(
					(item) => item.label != '非标'
				);
			}
			if (
				(this.ruleForm.onlineOrOffline == 1 || this.ruleForm.onlineOrOffline == 3) &&
				this.ruleForm.mortgageRate == 2
			) {
				return this.allDictionaries.Standard.filter(
					(item) => item.label == '半标'
				);
			}
			if ((this.ruleForm.onlineOrOffline == 1 || this.ruleForm.onlineOrOffline == 3)) {
				return this.allDictionaries.Standard.filter(
					(item) => item.label != '非标'
				);
			}
			if (
				this.ruleForm.onlineOrOffline == 2 &&
				this.ruleForm.mortgageRate == 1
			) {
				return this.allDictionaries.Standard.filter(
					(item) => item.label == '半标'
				);
			}
			if (
				this.ruleForm.onlineOrOffline == 2 &&
				this.ruleForm.mortgageRate != 1
			) {
				return this.allDictionaries.Standard.filter(
					(item) => item.label != '标准'
				);
			}
			if (this.ruleForm.onlineOrOffline == 2) {
				return this.allDictionaries.Standard.filter(
					(item) => item.label != '标准'
				);
			}
			return this.allDictionaries.Standard;
		},
	},
};
</script>
<style lang="scss" scoped>
.mb {
	margin-bottom: 10px;
}

.box-card {
	margin-bottom: 10px;
}

.demo-drawer__footer {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 30px 0;
}

.demo-drawer__footer button {
	width: 20% !important;
	margin: 0 10px;
}

.el-date-editor {
	width: 100%;
}

::v-deep .impose .el-input__inner {
	text-align: left !important;
}

.edit {
	p {
		font-weight: 800;
		margin-bottom: 20px;
	}

	span {
		text-indent: 40px;
		display: block;
		margin-bottom: 10px;
		font-weight: 800;
	}
}

.el-icon-circle-plus-outline {
	color: #409eff;
}

.el-icon-remove-outline {
	color: red;
}

::v-deep .el-input-number__increase {
	display: none;
}

::v-deep .el-input-number__decrease {
	display: none;
}

.requirement {
	display: flex;
	align-items: center;
	padding-left: 60px;
	margin: 20px 0;

	img {
		width: 20px !important;
		margin-right: 10px;
	}
}

// ::v-deep .demo-drawer__footer {
// 	display: flex;
// 	justify-content: center;
// 	width: 100%;
// 	padding-bottom: 20px;
// 	.el-button {
// 		width: 15%;
// 	}
// }</style>
